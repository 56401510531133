import html2pdf from 'html2pdf.js';
import { formatDateNotTime } from './displayDateTime';

export const downloadPDF = (invoice, globalTaxRateObj, currentGlobalTaxRateObj) => {
  const invoiceHTML = `
  <div id="invoice" style="margin:0px">
  <div>
  <div style="margin-bottom:40px;">
      <div>
        <h1 style="margin:0px">Invoice</h1>
      </div>
  </div>

  <div style="display: flex; justify-content: space-between; align-items: flex-start;">
    <div style="width: 66.6%; display: flex; justify-content: space-between; align-items: flex-start; margin-bottom: 40px;">
      <div>
        ${invoice?.space?.name ? `<h3 style="margin: 0;">${invoice.space.name}</h3>` : ''}
        ${invoice?.space?.number ? `<p style="margin: 0;">${invoice.space.number}</p>` : ''}
        ${invoice?.space?.address ? `<p style="margin: 0;">${invoice.space.address}</p>` : ''}
      </div>
    </div>
    <div style="width: 33.3%; display: flex; flex-direction: column;">
    ${
      invoice?.id
        ? `
      <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap;">
        <p style="margin: 0;">Invoice ID:</p>
        <b>${invoice.id}</b>
      </div>`
        : ''
    }
    
    ${
      invoice?.issued_date
        ? `
      <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap;">
        <p style="margin: 0;">Issued Date:</p>
        <b>${formatDateNotTime(invoice.issued_date)}</b>
      </div>`
        : ''
    }
    
    ${
      invoice?.due_date
        ? `
      <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap;">
        <p style="margin: 0;">Due Date:</p>
        <b>${formatDateNotTime(invoice.due_date)}</b>
      </div>`
        : ''
    }
    
    ${
      invoice?.paid !== undefined
        ? `
      <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap;">
        <p style="margin: 0;">Paid:</p>
        <b>${invoice.paid ? 'Yes' : 'No'}</b>
      </div>`
        : ''
    }
  </div>  
  </div>
 

    <div style="display: flex; justify-content: space-between; align-items: flex-start; margin:20px 0px">
    
    <div style="width: 66.6%; display: flex; justify-content: space-between; align-items: flex-start;">
      <div>
        <h3 style="margin: 0;">Invoice To:</h3>
        ${invoice?.member?.name ? `<h4 style="margin: 0;">${invoice.member.name}</h4>` : ''}
        ${invoice?.member?.email ? `<p style="margin: 0;"><b>${invoice.member.email}</b></p>` : ''}
        ${invoice?.member?.phoneNo ? `<p style="margin: 0;"><b>${invoice.member.phoneNo}</b></p>` : ''}
        ${invoice?.member?.address ? `<p style="margin: 0;"><b>${invoice.member.address}</b></p>` : ''}
      </div>
    </div>
  
    <div style="width: 33.3%; display: flex; flex-direction: column;">
  ${
    invoice?.total
      ? `
    <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap;">
      <p style="margin: 0;">Total Due:</p>
      <b>${invoice.total} ${invoice?.currency?.name}</b>
    </div>`
      : ''
  }
  
  ${
    invoice?.space?.bankName
      ? `
    <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap;">
      <p style="margin: 0;">Bank Name:</p>
      <b>${invoice.space.bankName}</b>
    </div>`
      : ''
  }
  
  ${
    invoice?.space?.branchName
      ? `
    <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap;">
      <p style="margin: 0;">Branch Name:</p>
      <b>${invoice.space.branchName}</b>
    </div>`
      : ''
  }
  
  ${
    invoice?.space?.iban
      ? `
    <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap;">
      <p style="margin: 0;">IBAN:</p>
      <b>${invoice.space.iban}</b>
    </div>`
      : ''
  }
  
  ${
    invoice?.space?.swiftCode
      ? `
    <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap;">
      <p style="margin: 0;">Swift Code:</p>
      <b>${invoice.space.swiftCode}</b>
    </div>`
      : ''
  }
  
  ${
    invoice?.space?.accountNumber
      ? `
    <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap;">
      <p style="margin: 0;">Account Number:</p>
      <b>${invoice.space.accountNumber}</b>
    </div>`
      : ''
  }
  
  ${
    invoice?.space?.institutionNumber
      ? `
    <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap;">
      <p style="margin: 0;">Institution Number:</p>
      <b>${invoice.space.institutionNumber}</b>
    </div>`
      : ''
  }
  
  ${
    invoice?.space?.branchCode
      ? `
    <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap;">
      <p style="margin: 0;">Branch Code:</p>
      <b>${invoice.space.branchCode}</b>
    </div>`
      : ''
  }
  
  ${
    invoice?.space?.routingNumber
      ? `
    <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap;">
      <p style="margin: 0;">Routing Number:</p>
      <b>${invoice.space.routingNumber}</b>
    </div>`
      : ''
  }
  
  ${
    invoice?.space?.transitNumber
      ? `
    <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap;">
      <p style="margin: 0;">Transit Number:</p>
      <b>${invoice.space.transitNumber}</b>
    </div>`
      : ''
  }
  
  ${
    invoice?.space?.taxNumber
      ? `
    <div style="width: 100%; display: flex; justify-content: space-between; flex-wrap: wrap;">
      <p style="margin: 0;">Tax Number:</p>
      <b>${invoice.space.taxNumber}</b>
    </div>`
      : ''
  }
</div>


  </div>
  

    <!-- Packages Table -->
    ${
      invoice.assignedPackage?.packages?.length > 0
        ? `
          <div style="margin-bottom: 20px">
            <h3>Packages</h3>
            <table>
              <thead align="center" style="padding: 5px;">
                <tr>
                  <th width="250px" style="padding: 5px;">Name</th>
                  <th width="250px" style="padding: 5px;">Duration</th>
                  <th width="250px" style="padding: 5px;">Rental Fee</th>
                  <th width="250px" style="padding: 5px;">Charges</th>
                  <th width="250px" style="padding: 5px;">Quantity</th>
                </tr>
              </thead>
              <tbody align="center">
                ${invoice.assignedPackage.packages
                  .map(
                    (pkg) => `
                    <tr key=${pkg.id} style="padding: 5px">
                      <td style="padding: 5px;">${pkg.package.name}</td>
                      <td style="padding: 5px;">
                        ${
                          invoice?.assignedPackage?.expiry_duration === 1
                            ? 'Daily'
                            : invoice.assignedPackage?.expiry_duration === 2
                            ? 'Weekly'
                            : 'Monthly'
                        }
                      </td>
                      <td style="padding: 5px;">${pkg.package.rentalFee}</td>
                      <td style="padding: 5px;">${pkg.package.serviceCharges}</td>
                      <td style="padding: 5px;">${pkg.quantity}</td>
                    </tr>
                  `
                  )
                  .join('')}
              </tbody>
            </table>
          </div>
        `
        : ''
    }

    <!-- Meeting Packages Table -->
    ${
      invoice.assignedPackage?.meeting_packages?.length > 0
        ? `
          <div style="margin-bottom: 20px">
            <h3>Meeting Packages</h3>
            <table>
              <thead style="padding: 5px;">
                <tr align="center">
                  <th width="250px" style="padding: 5px;"> Name</th>
                  <th width="250px" style="padding: 5px;">Duration</th>
                  <th width="250px" style="padding: 5px;">Rental Fee</th>
                  <th width="250px" style="padding: 5px;"> Charges</th>
                  <th width="250px" style="padding: 5px;"> Quantity</th>
                </tr>
              </thead>
              <tbody align="center">
                ${invoice.assignedPackage.meeting_packages
                  .map(
                    (meetingPkg) => `
                    <tr key=${meetingPkg.id}>
                      <td style="padding: 5px;">${meetingPkg.package.name}</td>
                      <td style="padding: 5px;">${
                        invoice?.assignedPackage?.expiry_duration === 1
                          ? 'Daily'
                          : invoice.assignedPackage?.expiry_duration === 2
                          ? 'Weekly'
                          : 'Monthly'
                      }</td>
                      <td style="padding: 5px;">${meetingPkg.package.rentalFee}</td>
                      <td style="padding: 5px;">${meetingPkg.package.serviceCharges}</td>
                      <td style="padding: 5px;">${meetingPkg.quantity}</td>
                    </tr>
                  `
                  )
                  .join('')}
              </tbody>
            </table>
          </div>
        `
        : ''
    }

   

    <!-- Purchases Table -->
    ${
      invoice.assignedPackage?.purchase
        ? `
          <div style="margin-bottom: 20px;">
            <h3>Purchases</h3>
            <table>
              <thead style="padding: 5px;">
                <tr align="center">
                  <th style="padding: 5px; width: 250px;">Name</th>
                  <th style="padding: 5px; width: 250px;">Price</th>
                  <th style="padding: 5px; width: 250px;">Quantity</th>
                  <th style="padding: 5px; width: 250px;">Tax</th>
                </tr>
              </thead>
              <tbody align="center">
                ${invoice.assignedPackage.purchase?.items
                  .map(
                    (purchase) => `
                    <tr key=${purchase.id} >
                      <td style="padding: 5px;">${purchase.product.name}</td>
                      <td style="padding: 5px;">${purchase.product.price}</td>
                      <td style="padding: 5px;">${purchase.quantity === 0 ? 1 : purchase.quantity}</td>
                      <td style="padding: 5px;">
                        ${purchase.product.inclusiveTax ? 'No Tax' : ''}
                        ${
                          !purchase.product.inclusiveTax && purchase.taxRateObj?.setOveralTax
                            ? `
                            ${
                              purchase.taxRateObj.serviceCharges
                                ? `
                              <div>
                                <span>Service: ${purchase.taxRateObj.serviceCharges || 0}%</span>
                              </div>
                            `
                                : `
                              <div>
                                <div>Rent Fee: ${purchase.taxRateObj.rentalFee || 0}%</div>
                                <div>Service: ${purchase.taxRateObj.serviceCharges || 0}%</div>
                              </div>
                            `
                            }
                          `
                            : ''
                        }
                        ${
                          !purchase.product.inclusiveTax && !purchase.taxRateObj?.setOveralTax
                            ? `
                            ${
                              globalTaxRateObj?.setOverallTax || currentGlobalTaxRateObj?.setOverallTax
                                ? `
                              <div>
                                <span>Service: ${
                                  globalTaxRateObj?.serviceCharges || currentGlobalTaxRateObj?.serviceCharges || 0
                                }%</span>
                              </div>
                            `
                                : `
                              <div>
                                <div>Rent Fee: ${
                                  globalTaxRateObj?.rentalFee || currentGlobalTaxRateObj?.rentalFee || 0
                                }%</div>
                                <div>Service: ${
                                  globalTaxRateObj?.serviceCharges || currentGlobalTaxRateObj?.serviceCharges || 0
                                }%</div>
                              </div>
                            `
                            }
                          `
                            : ''
                        }
                      </td>
                    </tr>
                  `
                  )
                  .join('')}
              </tbody>
            </table>
          </div>
        `
        : ''
    }


  </div>


  <div style="display: flex; justify-content: space-between; flex-direction: row; margin:40px 0px 0px 0px;">

  <div style="width:66.6%; display: flex; flex-direction: column;">
  ${
    invoice?.space?.invoiceInstructions
      ? `<p  style="white-space: pre-line; margin: 0;"> ${invoice?.space?.invoiceInstructions}</p>`
      : ``
  }
  </div>

  <div style="width: 33.3%; display: flex; flex-direction: column;">
  <div style="width: 100%; display: flex; justify-content: space-between;">
    <p style="margin: 0;">Sub Total:</p>
    <b>${invoice?.subtotal} ${invoice?.currency?.name}</b>
  </div>

  ${
    invoice?.type === 2 || invoice?.type === 6
      ? `
    <div style="width: 100%; display: flex; flex-direction: column">
      <div style="width: 100%; display: flex; justify-content: space-between;">
        ${
          invoice?.setOveralTax
            ? `
          <p style="margin: 0;">Overal Tax:</p>
          <b style="font-weight: bold; margin: 0;">${invoice?.serviceCharges} %</b>
          `
            : `
          <p style="margin: 0;">Service Charges:</p>
          <b style="font-weight: bold; margin: 0;">${invoice?.serviceCharges} %</b>
          `
        }
      </div>

      <div style="width: 100%; display: flex; justify-content: space-between;">
        <p style="margin: 0;">${invoice?.setOveralTax ? '' : 'Rental Fee:'}</p>
        <b style="font-weight: bold; margin: 0;">${invoice?.setOveralTax ? '' : `${invoice?.rentalFee} %`}</b>
      </div>
    </div>
    `
      : `
    <div style="width: 100%; display: flex; justify-content: space-between;">
      <p style="margin: 0;">Total Tax:</p>
      <b>${invoice?.totalTax} ${invoice?.currency?.name}</b>
    </div>
    `
  }

  <div style="width: 100%; display: flex; justify-content: space-between;">
    <p style="margin: 0;">Discount:</p>
    <b>${invoice?.discount_percentage} %</b>
  </div>

  <div style="width: 100%; display: flex; justify-content: space-between;">
    <p style="margin: 0;">Adjustment:</p>
    <b>${invoice?.adjustment}</b>
  </div>

  <hr style="width:100%;" />

  <div style="width: 100%; display: flex; justify-content: space-between;">
    <p style="margin: 0;">Total:</p>
    <b>${invoice?.total?.toFixed(2)} ${invoice?.currency?.name}</b>
  </div>
</div>

</div>

      </div>
    </div>
`;

  const pdfOptions = {
    filename: `INV—${formatDateNotTime(invoice.due_date)}.pdf`, // Specify the desired file name
    margin: 20,
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  };

  html2pdf().from(invoiceHTML).set(pdfOptions).save();
  // html2pdf().from(invoiceHTML).save();
};
