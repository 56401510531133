/* eslint-disable camelcase */
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  Container,
  IconButton,
  Typography,
  Stack,
  Divider,
  Avatar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
} from '@mui/material';
import { formatDateNotTime } from '../utils/displayDateTime';
import { downloadPDF } from '../utils/downloadPdf';
import Loader from '../components/Loader/Loader';
import { useGetInvoicePayUrlQuery, useGetInvoiceQuery } from '../Slices/spaceApi';
import Iconify from '../components/iconify';

const InvoiceViewPage = () => {
  const { id } = useParams();
  const { spaceId, currentSpaceId, stripe, currentStripe } = useSelector((item) => item.spaceReducer);

  const { data, isLoading } = useGetInvoiceQuery({ spaceId: spaceId || currentSpaceId, invoiceId: id });

  const { data: getUrl, isSuccess } = useGetInvoicePayUrlQuery(
    { spaceId: spaceId || currentSpaceId, invoiceId: id },
    {
      skip: !data?.invoiceId || !data?.stripeInvoice || data?.paid,
    }
  );

  const redirectPay = () => {
    if (isSuccess) {
      window.location.href = getUrl?.hostedUrl;
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{`INV—${data.invoiceId}`}</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          INV—{data?.invoiceId}{' '}
          {+data?.type === 2 || +data?.type === 6
            ? '(Booking Invoice)'
            : +data?.type === 3
            ? '(Secuirty Deposit Invoice)'
            : null}
        </Typography>

        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <IconButton
            color="secondary"
            onClick={() =>
              downloadPDF(data, {
                setOverallTax: data?.setOveralTax,
                rentalFee: data?.rentalFee,
                serviceCharges: data?.serviceCharges,
              })
            }
          >
            <Iconify icon="mingcute:download-3-fill" />
          </IconButton>

          {isSuccess && (stripe || currentStripe === 'true') && (
            <Button variant="contained" onClick={redirectPay}>
              Pay Now
            </Button>
          )}
        </Box>

        <Card sx={{ mt: 2 }}>
          <Stack flexDirection={'row'} justifyContent={'space-between'} px={4} pt={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Box>
                  <Stack flexDirection={'row'} alignItems={'center'}>
                    <Avatar src={data?.space?.imageUrls} className="invoice" />
                    {data?.space?.name && (
                      <Typography variant="h6" ml={1}>
                        {data?.space?.name}
                      </Typography>
                    )}
                  </Stack>
                  {data?.space?.number && <Typography mt={2}>{data?.space?.number}</Typography>}
                  {data?.space?.address && <Typography> {data?.space?.address}</Typography>}
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack gap={0.3} minWidth={'200px'}>
                  <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                    <Typography minWidth={'120px'}>Invoice ID: </Typography>
                    <Typography fontWeight={'bold'}>{data?.invoiceId}</Typography>
                  </Box>
                  <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                    <Typography minWidth={'120px'}>Issued Date: </Typography>
                    <Typography fontWeight={'bold'}>{formatDateNotTime(data?.issued_date)}</Typography>
                  </Box>
                  <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                    <Typography minWidth={'120px'}>Due Date: </Typography>
                    <Typography fontWeight={'bold'}>{formatDateNotTime(data?.due_date)}</Typography>
                  </Box>
                  <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                    <Typography minWidth={'120px'}>Paid: </Typography>
                    <Typography fontWeight={'bold'}> {data?.paid ? 'Yes' : 'No'}</Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Stack>

          <Divider sx={{ my: 3 }} />

          <Stack flexDirection={'row'} justifyContent={'space-between'} px={4} mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Stack gap={0.3}>
                  <Typography variant="h5">Invoice To:</Typography>
                  {data?.member?.name && (
                    <Typography variant="h6" mt={3}>
                      {data?.member?.name}
                    </Typography>
                  )}

                  {data?.member?.email && (
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography>{data?.member?.email}</Typography>
                    </Box>
                  )}

                  {data?.member?.phoneNo && (
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography>{data?.member?.phoneNo}</Typography>
                    </Box>
                  )}

                  {data?.member?.address && (
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography>{data?.member?.address}</Typography>
                    </Box>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack gap={0.3}>
                  <Typography variant="h5">Bill To:</Typography>

                  <Box mt={3} display={'flex'} flexDirection={'row'} justifyContent="space-between">
                    <Typography minWidth={'120px'}>Total Due: </Typography>
                    <Typography fontWeight={'bold'}>
                      {data?.total?.toFixed(2)} {data?.currency?.name || data?.currentCurrency?.name}
                    </Typography>
                  </Box>

                  {data?.space?.bankName && (
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography minWidth={'120px'}> Bank Name: </Typography>
                      <Typography fontWeight={'bold'}>{data?.space?.bankName}</Typography>
                    </Box>
                  )}

                  {data?.space?.branchName && (
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography minWidth={'120px'}>Branch Name:</Typography>
                      <Typography fontWeight={'bold'}>{data?.space?.branchName}</Typography>
                    </Box>
                  )}

                  {data?.space.iban && (
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography minWidth={'120px'}>IBAN:</Typography>
                      <Typography fontWeight={'bold'}>{data?.space.iban}</Typography>
                    </Box>
                  )}

                  {data?.space.swiftCode && (
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography minWidth={'120px'}>Swift Code:</Typography>
                      <Typography fontWeight={'bold'}>{data?.space.swiftCode}</Typography>
                    </Box>
                  )}

                  {data?.space.accountNumber && (
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography minWidth={'120px'}>Account Number:</Typography>
                      <Typography fontWeight={'bold'}>{data?.space.accountNumber}</Typography>
                    </Box>
                  )}

                  {data?.space.institutionNumber && (
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography minWidth={'120px'}>Institution Number:</Typography>
                      <Typography fontWeight={'bold'}>{data?.space.institutionNumber}</Typography>
                    </Box>
                  )}

                  {data?.space.branchCode && (
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography minWidth={'120px'}>Branch Code:</Typography>
                      <Typography fontWeight={'bold'}>{data?.space.branchCode}</Typography>
                    </Box>
                  )}

                  {data?.space.routingNumber && (
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography minWidth={'120px'}>Routing Number:</Typography>
                      <Typography fontWeight={'bold'}>{data?.space.routingNumber}</Typography>
                    </Box>
                  )}

                  {data?.space.transitNumber && (
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography minWidth={'120px'}>Transit Number:</Typography>
                      <Typography fontWeight={'bold'}>{data?.space.transitNumber}</Typography>
                    </Box>
                  )}

                  {data?.space.taxNumber && (
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography minWidth={'120px'}>Tax Number:</Typography>
                      <Typography fontWeight={'bold'}>{data?.space.taxNumber}</Typography>
                    </Box>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Stack>

          {data?.assignedPackage?.packages?.length > 0 &&
            renderPackageTable(data?.assignedPackage.packages, {
              setOverallTax: data?.setOveralTax,
              rentalFee: data?.rentalFee,
              serviceCharges: data?.serviceCharges,
            })}

          {data?.assignedPackage?.meeting_packages?.length > 0 &&
            renderMeetingPackagesTable(data?.assignedPackage.meeting_packages, {
              setOverallTax: data?.setOveralTax,
              rentalFee: data?.rentalFee,
              serviceCharges: data?.serviceCharges,
            })}

          {data?.assignedPackage?.purchase?.items?.length > 0 &&
            renderPurchaseTable(data?.assignedPackage?.purchase?.items, {
              setOverallTax: data?.setOveralTax,
              rentalFee: data?.rentalFee,
              serviceCharges: data?.serviceCharges,
            })}

          <Stack flexDirection={'row'} justifyContent={'space-between'} px={4} pb={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Box>
                  {data?.space?.invoiceInstructions && (
                    <Typography style={{ whiteSpace: 'pre-line' }}>{data?.space?.invoiceInstructions}</Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack minWidth={'250px'} gap={0.3}>
                  <>
                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography minWidth={'120px'}>Sub Total: </Typography>
                      <Typography fontWeight={'bold'}>
                        {data?.subtotal?.toFixed(2)} {data?.currency?.name}
                      </Typography>
                    </Box>

                    {data?.type === 2 || data?.type === 6 ? (
                      <>
                        <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                          {data?.setOveralTax ? (
                            <>
                              <Typography minWidth={'120px'}>Overal Tax:</Typography>
                              <Typography fontWeight={'bold'}>{data?.serviceCharges} %</Typography>
                            </>
                          ) : (
                            <>
                              <Typography minWidth={'120px'}>Service Charges:</Typography>
                              <Typography fontWeight={'bold'}>{data?.serviceCharges} %</Typography>
                            </>
                          )}
                        </Box>

                        <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                          <Typography minWidth={'120px'}>{data?.setOveralTax ? '' : 'Rental Fee:'} </Typography>
                          <Typography fontWeight={'bold'}>
                            {data?.setOveralTax ? '' : `${data?.rentalFee} %`}
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                          <Typography minWidth={'120px'}>Total Tax:</Typography>
                          <Typography fontWeight={'bold'}>
                            {data?.totalTax} {data?.currency?.name}
                          </Typography>
                        </Box>
                      </>
                    )}

                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography minWidth={'120px'}>Discount: </Typography>
                      <Typography fontWeight={'bold'}>{data?.discount_percentage} %</Typography>
                    </Box>

                    {data?.discountCode && (
                      <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                        <Typography minWidth={'120px'}>Discount Code: </Typography>
                        <Typography fontWeight={'bold'}>
                          {data?.discountCode?.fixedAmount
                            ? data?.discountCode?.amount || 0
                            : `${data?.discountCode?.amount || 0} %`}
                        </Typography>
                      </Box>
                    )}

                    <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                      <Typography minWidth={'120px'}>Adjustment: </Typography>
                      <Typography fontWeight={'bold'}>{data?.adjustment}</Typography>
                    </Box>

                    <Divider sx={{ my: 1 }} />
                  </>

                  <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">
                    <Typography minWidth={'120px'}>Total: </Typography>
                    <Typography fontWeight={'bold'}>
                      {data?.total?.toFixed(2)} {data?.currency?.name}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Card>
      </Container>
    </>
  );
};

export default InvoiceViewPage;

const renderPackageTable = (packages, globalTaxRateObj, currentGlobalTaxRateObj) => (
  <Box mb={3}>
    <Typography variant="h5" px="16px">
      Packages
    </Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Name</TableCell>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Duration</TableCell>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Rental Fee</TableCell>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Charges</TableCell>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Quantity</TableCell>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Tax</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {packages.map((pkg) => (
          <TableRow key={pkg.id}>
            <TableCell>{pkg.package.name}</TableCell>
            <TableCell>
              {pkg.package.expiry_duration === 1 ? 'Daily' : pkg.package.expiry_duration === 2 ? 'Weekly' : 'Monthly'}
            </TableCell>
            <TableCell>{pkg.package.rentalFee}</TableCell>
            <TableCell>{pkg.package.serviceCharges}</TableCell>
            <TableCell>{pkg.quantity}</TableCell>
            {pkg.package.taxRate ? (
              <TableCell>
                {pkg?.package?.taxRate?.serviceCharges ? (
                  <Stack flexDirection={'row'}>
                    <Typography variant="subtitle1" mr={1}>
                      Service:{' '}
                    </Typography>
                    <Typography>{pkg?.package?.taxRate?.serviceCharges || 0}%</Typography>
                  </Stack>
                ) : (
                  <Stack>
                    <Stack flexDirection={'row'}>
                      <Typography variant="subtitle1" mr={1}>
                        Rent Fee:
                      </Typography>

                      <Typography>{pkg?.package?.taxRate?.rentalFee || 0}%</Typography>
                    </Stack>

                    <Stack flexDirection={'row'}>
                      <Typography variant="subtitle1" mr={1}>
                        Service:
                      </Typography>

                      <Typography>{pkg?.package?.taxRate?.serviceCharges || 0}%</Typography>
                    </Stack>
                  </Stack>
                )}
              </TableCell>
            ) : (
              <TableCell>
                {globalTaxRateObj?.setOverallTax || currentGlobalTaxRateObj?.setOverallTax ? (
                  <Stack>
                    <Typography variant="subtitle1" mr={1}>
                      Service:
                    </Typography>

                    <Typography>
                      {globalTaxRateObj?.serviceCharges || currentGlobalTaxRateObj?.serviceCharges || 0}%
                    </Typography>
                  </Stack>
                ) : (
                  <>
                    <Stack flexDirection={'row'}>
                      <Typography variant="subtitle1" mr={1}>
                        Rent Fee:
                      </Typography>

                      <Typography>{globalTaxRateObj?.rentalFee || currentGlobalTaxRateObj?.rentalFee || 0}%</Typography>
                    </Stack>

                    <Stack flexDirection={'row'}>
                      <Typography variant="subtitle1" mr={1}>
                        Service:
                      </Typography>

                      <Typography>
                        {globalTaxRateObj?.serviceCharges || currentGlobalTaxRateObj?.serviceCharges || 0}%
                      </Typography>
                    </Stack>
                  </>
                )}
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <Divider />
  </Box>
);

const renderMeetingPackagesTable = (meetingPackages, globalTaxRateObj, currentGlobalTaxRateObj) => (
  <Box mb={3}>
    <Typography variant="h5" px="16px">
      Meeting Packages
    </Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Name</TableCell>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Duration</TableCell>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Rental Fee</TableCell>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Charges</TableCell>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Quantity</TableCell>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Tax</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {meetingPackages.map((meetingPkg) => (
          <TableRow key={meetingPkg.id}>
            <TableCell>{meetingPkg.package.name}</TableCell>
            <TableCell>
              {meetingPkg.package.expiry_duration === 1
                ? 'Daily'
                : meetingPkg.package.expiry_duration === 2
                ? 'Weekly'
                : 'Monthly'}
            </TableCell>
            <TableCell>{meetingPkg.package.rentalFee}</TableCell>
            <TableCell>{meetingPkg.package.serviceCharges}</TableCell>
            <TableCell>{meetingPkg.quantity}</TableCell>
            {meetingPkg.package?.taxRate ? (
              <TableCell>
                {meetingPkg.package?.taxRate?.serviceCharges ? (
                  <Stack flexDirection={'row'}>
                    <Typography variant="subtitle1" mr={1}>
                      Service:
                    </Typography>
                    <Typography>{meetingPkg.package?.taxRate?.serviceCharges || 0}%</Typography>
                  </Stack>
                ) : (
                  <Stack>
                    <Stack flexDirection={'row'}>
                      <Typography variant="subtitle1" mr={1}>
                        Rent Fee:
                      </Typography>

                      <Typography>{meetingPkg.package?.taxRate?.rentalFee || 0}%</Typography>
                    </Stack>

                    <Stack flexDirection={'row'}>
                      <Typography variant="subtitle1" mr={1}>
                        Service:
                      </Typography>

                      <Typography>{meetingPkg.package?.taxRate?.serviceCharges || 0}%</Typography>
                    </Stack>
                  </Stack>
                )}
              </TableCell>
            ) : (
              <TableCell>
                {globalTaxRateObj?.setOverallTax || currentGlobalTaxRateObj?.setOverallTax ? (
                  <Stack flexDirection={'row'}>
                    <Typography variant="subtitle1" mr={1}>
                      Service:
                    </Typography>

                    <Typography>
                      {globalTaxRateObj?.serviceCharges || currentGlobalTaxRateObj?.serviceCharges || 0}%
                    </Typography>
                  </Stack>
                ) : (
                  <>
                    <Stack flexDirection={'row'}>
                      <Typography variant="subtitle1" mr={1}>
                        Rent Fee:
                      </Typography>

                      <Typography>{globalTaxRateObj?.rentalFee || currentGlobalTaxRateObj?.rentalFee || 0}%</Typography>
                    </Stack>

                    <Stack flexDirection={'row'}>
                      <Typography variant="subtitle1" mr={1}>
                        Service:
                      </Typography>

                      <Typography>
                        {globalTaxRateObj?.serviceCharges || currentGlobalTaxRateObj?.serviceCharges || 0}%
                      </Typography>
                    </Stack>
                  </>
                )}
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <Divider />
  </Box>
);

const renderPurchaseTable = (meetingPackages, globalTaxRateObj, currentGlobalTaxRateObj) => (
  <Box mb={3}>
    <Typography variant="h5" px="16px">
      Purchases
    </Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Name</TableCell>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Price</TableCell>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Quantity</TableCell>
          <TableCell sx={{ backgroundColor: 'transparent' }}>Tax</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {meetingPackages.map((meetingPkg) => (
          <TableRow key={meetingPkg.id}>
            <TableCell>{meetingPkg?.product?.name}</TableCell>
            <TableCell>{meetingPkg?.product?.price}</TableCell>
            <TableCell>{meetingPkg.quantity === 0 ? 1 : meetingPkg.quantity}</TableCell>
            {meetingPackages?.product?.inclusiveTax ? (
              <TableCell>No Tax</TableCell>
            ) : meetingPkg.taxRateObj?.setOveralTax ? (
              <TableCell>
                {meetingPkg?.taxRateObj?.serviceCharges ? (
                  <Stack flexDirection={'row'}>
                    <Typography variant="subtitle1" mr={1}>
                      Service:
                    </Typography>
                    <Typography>{meetingPkg?.taxRateObj?.serviceCharges || 0}%</Typography>
                  </Stack>
                ) : (
                  <Stack>
                    <Stack flexDirection={'row'}>
                      <Typography variant="subtitle1" mr={1}>
                        Rent Fee:
                      </Typography>

                      <Typography>{meetingPkg?.taxRateObj?.rentalFee || 0}%</Typography>
                    </Stack>

                    <Stack flexDirection={'row'}>
                      <Typography variant="subtitle1" mr={1}>
                        Service:
                      </Typography>

                      <Typography>{meetingPkg?.taxRateObj?.serviceCharges || 0}%</Typography>
                    </Stack>
                  </Stack>
                )}
              </TableCell>
            ) : (
              <TableCell>
                {globalTaxRateObj?.setOverallTax || currentGlobalTaxRateObj?.setOverallTax ? (
                  <Stack flexDirection={'row'}>
                    <Typography variant="subtitle1" mr={1}>
                      Service:
                    </Typography>

                    <Typography>
                      {globalTaxRateObj?.serviceCharges || currentGlobalTaxRateObj?.serviceCharges || 0}%
                    </Typography>
                  </Stack>
                ) : (
                  <>
                    <Stack flexDirection={'row'}>
                      <Typography variant="subtitle1" mr={1}>
                        Rent Fee:
                      </Typography>

                      <Typography>{globalTaxRateObj?.rentalFee || currentGlobalTaxRateObj?.rentalFee || 0}%</Typography>
                    </Stack>

                    <Stack flexDirection={'row'}>
                      <Typography variant="subtitle1" mr={1}>
                        Service:
                      </Typography>

                      <Typography>
                        {globalTaxRateObj?.serviceCharges || currentGlobalTaxRateObj?.serviceCharges || 0}%
                      </Typography>
                    </Stack>
                  </>
                )}
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <Divider />
  </Box>
);
