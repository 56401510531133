import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import CreateEmployee from '../components/Employee/CreateEmployeeCheck';
import ViewEmployee from '../components/Employee/ViewEmployee';
import EmployeeCheck from '../components/Employee/EmployeeCheck';
import { CustomAlert, DeleteComponent } from '../utils/Alert';
import { useDeleteUserMutation, useGetCompaniesUserQuery } from '../Slices/spaceApi';
import Iconify from '../components/iconify';

const EmployeePage = () => {
  const { spaceId, currentSpaceId, userId, currentUserId, name, currentName } = useSelector(
    (item) => item.spaceReducer
  );
  const [loading, setLoading] = useState(false);
  const [openView, setOpenView] = useState({ open: false, user: {} });

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    watch,
    setValue,
  } = useForm();

  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [openFilter, setOpenFilter] = useState({ edit: false, open: false, id: 'createId', user: {} });

  const onCloseViewFilter = () => {
    setOpenView({ open: false, user: {} });
  };

  const [activePage, setActivePage] = useState(1);

  const [openCheck, setOpenCheck] = useState(false);
  const [selectDelete, setSelectDelete] = useState(false);
  const [messageAdd, setMessageAdd] = useState('');

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const { data, isFetching } = useGetCompaniesUserQuery({
    spaceId: spaceId || currentSpaceId,
    companyId: userId || currentUserId,
    status: searchValue,
    activePage,
    page_size: 30,
  });

  const [deleteUser, resultStats] = useDeleteUserMutation();

  const navigate = useNavigate();

  const handleDelete = (item) => {
    setSelectDelete(item);
    setOpenCheck(true);
  };

  const handleCloseCheck = () => {
    setOpenCheck(false);
  };

  const handleOpenFilterUser = (item) => {
    setOpenFilter({ open: true, edit: true, id: item.id, user: item });
  };

  const onCloseFilter = () => {
    setOpenFilter({ edit: false, open: false, id: 'createId', user: {} });
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      setType(true);
      setOpen(true);
      setMessageAdd('User deleted successfully.');
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setOpen(true);
      setType(false);
    }
  }, [resultStats]);

  const renderCards = () => (
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <TextField
            variant="outlined"
            label="Search"
            sx={{ width: { xs: '100%', sm: '70%', md: '50%' } }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <Typography p={2}>Loading..</Typography>
            ) : data?.users?.length > 0 ? (
              data?.users?.map((user, idx) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <Box display={'flex'} alignItems={'center'}>
                      <Box
                        sx={{
                          transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                          padding: '3px',
                          border: '1px dashed rgba(145, 158, 171, 0.2)',
                          borderRadius: '50%',
                        }}
                      >
                        <Avatar src={user.profileImg || '/assets/placeholder.svg'} />
                      </Box>
                      <Box ml={1}>
                        <Typography>{user.name}</Typography>
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell>{user.email}</TableCell>

                  <TableCell>
                    <Stack flexDirection={'row'}>
                      <IconButton color="secondary" onClick={() => setOpenView({ open: true, user })}>
                        <Iconify icon={'solar:eye-bold'} />
                      </IconButton>

                      <IconButton
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenFilterUser(user);
                        }}
                      >
                        <Iconify icon={'eva:edit-fill'} />
                      </IconButton>

                      <IconButton
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation(); // Stop the event propagation here
                          handleDelete(user);
                        }}
                      >
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography variant="h6" paragraph mb={0}>
                    There's no employee
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>

          {data?.users?.length > 0 && !isFetching && (
            <TableFooter>
              <TableCell colSpan={5}>
                <Pagination
                  count={data?.totalPages}
                  color="primary"
                  page={activePage}
                  onChange={handlePageChange}
                  sx={{ '.MuiPagination-ul': { justifyContent: 'center', width: '100%' } }}
                />
              </TableCell>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Grid>
  );

  return (
    <>
      <Helmet>
        <title> Employees | {name || currentName} </title>
      </Helmet>

      <Container>
        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: 3 }}>
          <Typography variant="h4">Employees</Typography>

          <Button
            sx={{ float: 'right' }}
            variant="contained"
            onClick={() => setOpenFilter({ edit: false, open: true, id: 'createId', user: {} })}
          >
            Add Employee
          </Button>
        </Stack>

        <Grid container spacing={2}>
          {renderCards()}

          {selectDelete?.id && (
            <DeleteComponent
              handleCloseCheck={handleCloseCheck}
              openCheck={openCheck}
              name={'user'}
              deleted={() => deleteUser({ spaceId: spaceId || currentSpaceId, userId: selectDelete?.id })}
            />
          )}

          <CustomAlert
            type={type ? 'success' : 'error'}
            message={messageAdd}
            open={open}
            autoHideDuration={5000}
            setOpen={setOpen}
          />

          {openFilter?.edit ? (
            <EmployeeCheck
              control={control}
              errors={errors}
              handleSubmit={handleSubmit}
              onCloseFilter={onCloseFilter}
              openFilter={openFilter?.open}
              edit={openFilter?.edit}
              register={register}
              setValue={setValue}
              trigger={trigger}
              watch={watch}
              uniqueId={openFilter?.id}
              loading={loading}
              setLoading={setLoading}
              user={openFilter?.user}
            />
          ) : (
            <CreateEmployee
              control={control}
              errors={errors}
              handleSubmit={handleSubmit}
              onCloseFilter={onCloseFilter}
              openFilter={openFilter?.open}
              edit={openFilter?.edit}
              register={register}
              setValue={setValue}
              trigger={trigger}
              watch={watch}
              uniqueId={openFilter?.id}
              loading={loading}
              setLoading={setLoading}
              // user={openFilter?.user}
            />
          )}

          <ViewEmployee openFilter={openView?.open} onCloseFilter={onCloseViewFilter} {...openView.user} />
        </Grid>
      </Container>
    </>
  );
};

export default EmployeePage;
