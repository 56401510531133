import { Box, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { removeFieldsWithId } from '../../utils/removeIdFromFieldNames';
import { CustomAlert } from '../../utils/Alert';
import Iconify from '../iconify';
import Scrollbar from '../scrollbar/Scrollbar';
import EmployeeForm from './EmployeeForm';
import { useCreateUserMutation } from '../../Slices/spaceApi';

const CreateEmployee = ({
  openFilter,
  onCloseFilter,
  handleSubmit,
  errors,
  register,
  trigger,
  watch,
  control,
  setValue,
  edit,
  loading,
  setLoading,
  uniqueId,
}) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);
  const [message, setMessage] = useState('');

  const [createUser, resultStats] = useCreateUserMutation();

  const onSubmit = (data) => {
    delete data?.[`file_${uniqueId}`];
    delete data?.[`cnic-file_${uniqueId}`];

    if (data?.[`profileImg_${uniqueId}`] && data?.[`profileImg_${uniqueId}`].startsWith('https')) {
      delete data?.[`profileImg_${uniqueId}`];
    }

    if (data?.[`cnicImg_${uniqueId}`] && data?.[`cnicImg_${uniqueId}`].startsWith('https')) {
      delete data?.[`cnicImg_${uniqueId}`];
    }

    const updatedData = removeFieldsWithId(data, uniqueId);

    const filteredKeyData = Object.entries(updatedData).reduce((acc, [key, value]) => {
      if (!key.includes('_')) {
        acc[key] = value;
      }
      return acc;
    }, {});

    const filteredData = Object.fromEntries(
      Object.entries(filteredKeyData).filter(([key, value]) => value !== undefined && value !== null && value !== '')
    );

    createUser({
      spaceId: spaceId || currentSpaceId,
      data: {
        cnicImage: filteredData?.cnicImg,
        name: filteredData?.name,
        email: filteredData?.email,
        phoneNo: filteredData?.phoneNo,
        address: filteredData?.address,
        cnicNo: filteredData?.cnicNo,
        description: filteredData?.description,
        profileImg: filteredData?.profileImg,
        exprienceYears: filteredData?.exprienceYears,
      },
    });
  };

  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoading(true);
    } else if (resultStats?.isSuccess) {
      setType(true);
      setLoading(false);
      setOpen(true);
      setMessage('Member added successfully.');
      const fieldData = {
        [`name_${uniqueId}`]: '',
        [`email_${uniqueId}`]: '',
        [`description_${uniqueId}`]: '',
        [`profileImg_${uniqueId}`]: '',
        [`cnicImg_${uniqueId}`]: '',
        [`phoneNo_${uniqueId}`]: '',
        [`address_${uniqueId}`]: '',
        [`experienceYears_${uniqueId}`]: '',
        [`cnicNo_${uniqueId}`]: '',
      };

      setValue(`file_${uniqueId}`, '');
      setValue(`cnic-file_${uniqueId}`, '');

      Object.keys(fieldData).forEach((fieldName) => {
        setValue(fieldName, fieldData[fieldName]);
      });
      onCloseFilter();
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);

      setMessage(errorMessage);

      setLoading(false);
      setOpen(true);
      setType(false);
    }
  }, [resultStats]);

  return (
    <>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: { xs: '98%', sm: '375px' }, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {' '}
            Add Employee
          </Typography>
          <IconButton onClick={onCloseFilter} color="secondary">
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={2} sx={{ p: 1 }}>
            <EmployeeForm
              errors={errors}
              register={register}
              trigger={trigger}
              watch={watch}
              control={control}
              uniqueId={uniqueId}
              setVALUES={setValue}
              setLoading={setLoading}
              loading={loading}
              edit={edit}
            />
          </Stack>
        </Scrollbar>

        <Box sx={{ boxShadow: (theme) => theme.customShadows.dropdown }}>
          <Box sx={{ px: 3, my: 2 }}>
            <LoadingButton
              onClick={handleSubmit(onSubmit)}
              loading={loading || resultStats?.isLoading}
              variant="contained"
              fullWidth
            >
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
                Add Employee
              </Stack>
            </LoadingButton>
          </Box>
        </Box>
      </Drawer>

      <CustomAlert type={type ? 'success' : 'error'} message={message} open={open} setOpen={setOpen} />
    </>
  );
};

export default CreateEmployee;
